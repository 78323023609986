<template>
  <v-menu
    v-if="$route.path !== '/'"
    v-model="legendMenu"
    content-class="menu"
    :close-on-content-click="false"
    :nudge-width="100"
    top
    offset-y
  >
    <template v-slot:activator="{ on, attrs }">
      <v-btn
        color="RMwhite"
        class="pa-0 button-legend"
        min-height="40"
        min-width="40"
        width="40"
        height="40"
        v-bind="attrs"
        :disabled="!isLegendActive"
        style="pointer-events: auto"
        v-on="on"
        @click="layerLegend()"
      >
        <SvgIcon
          :title="$t('BottomSection.legend')"
          :path="
            'M21.5738497,9 C22.2378289,9 22.777779,9.54000282 22.777779,10.2040469 L22.777779,13.4626201 C22.777779,14.1266642 22.2378289,14.666667 21.5738497,14.666667 L18.3155946,14.666667 C17.6516153,14.666667 17.1116653,14.1266642 17.1116653,13.4626201 L17.1116653,10.2040469 C17.091397,9.54000282 17.6305287,9 18.3155946,9 L21.5738497,9 Z M5.75162635,9.25878222 L8.5397528,13.9126396 C8.74014995,14.2576825 8.49531676,14.666667 8.09365257,14.666667 L2.54015078,14.666667 C2.11584254,14.666667 1.87013053,14.2357942 2.07138865,13.9126396 L4.85942588,9.25878222 C5.05982303,8.91373926 5.55123812,8.91373926 5.75162635,9.25878222 Z M12.8611118,9 C14.4258544,9 15.6944453,10.2685909 15.6944453,11.8333335 C15.6944453,13.3980761 14.4258544,14.666667 12.8611118,14.666667 C11.2963691,14.666667 10.0277783,13.3980761 10.0277783,11.8333335 C10.0277783,10.2685909 11.2963691,9 12.8611118,9 Z'
          "
        />
      </v-btn>
    </template>

    <v-card
      class="mx-auto transparent-bg"
      elevation="0"
      min-width="200"
      height="300"
      tile
    >
      <v-overlay :value="overlay">
        <v-progress-circular
          indeterminate
          size="64"
        />
      </v-overlay>
      <div
        v-if="isRaster"
        class="white-bg"
        style="min-height: 100%"
      >
        <v-row class="font-weight-black text-subtitle-1 pt-6 px-8">
          {{ $t(`${rasterLayerName}.label`) }}{{ $t(`${rasterLayerName}.udm`) }}
        </v-row>
        <v-row class="px-3 flex-no-wrap">
          <v-col
            cols="1"
            class="py-5"
          >
            <div
              v-for="(gradient, index) in rasterGradients.slice().reverse()"
              :key="index"
            >
              <div :style="`height: 40px; width: 20px; background: linear-gradient(to top, ${gradient.join(', ')})`" />
            </div>
          </v-col>

          <v-col>
            <v-list
              class="transparent-bg"
              dense
              flat
            >
              <v-list-item-group
                v-model="geoserver_data.rasterLegend_selectedLabel"
                multiple
              >
                <v-list-item
                  v-for="(label, index) in rasterLabels.slice().reverse()"
                  :key="index"
                  @input="setEnv(index)"
                >
                  <template v-slot:default="{ active }">
                    <v-list-item-content
                      v-if="rasterLabels[1]"
                      class="body-2"
                    >
                      <v-list-item-title
                        style="overflow-x: auto; text-overflow: clip;"
                      >
                        {{ label }}
                      </v-list-item-title>
                    </v-list-item-content>

                    <v-list-item-action v-if="rasterClickable[index]">
                      <v-icon
                        v-if="active"
                        color="grey lighten-1"
                        small
                      >
                        mdi-eye-off
                      </v-icon>

                      <v-icon
                        v-else
                        small
                      >
                        mdi-eye
                      </v-icon>
                    </v-list-item-action>
                  </template>
                </v-list-item>
              </v-list-item-group>
            </v-list>
          </v-col>
        </v-row>
      </div>
      <!-- <v-divider /> -->
      <div
        v-for="(name, i) in vectorLayerName"
        :key="i"
        class="mt-3 white-bg"
      >
        <v-row class="font-weight-black text-subtitle-1 pt-8 px-8">
          {{ $t(`${name}.label`) }}{{ $t(`${name}.udm`) }}
        </v-row>
        <v-row class="px-3 flex-no-wrap">
          <v-col
            cols="1"
            class="py-5"
          >
            <div
              v-for="(color, n) in vectorColorsArray[i]"
              :key="n"
              style="padding: 10px"
            >
              <div :style="`height: 20px; width: 20px; background-color:${color}; border: 2px solid ${vectorStrokesArray[i][n]}; border-radius: 3px`" />
            </div>
          </v-col>
          <v-col>
            <v-list
              class="transparent-bg"
              dense
              flat
            >
              <v-list-item-group
                v-model="geoserver_data.vectorLegend_selectedLabel[name.split('_serviceID:')[0]]"
                multiple
              >
                <v-list-item
                  v-for="(label, index) in vectorLabelsArray[i].slice().reverse()"
                  :key="index"
                  @input="setParamRange(name.split('_serviceID:')[0], i, vectorClickable[name.split('_serviceID:')[0]][index])"
                >
                  <template v-slot:default="{ active }">
                    <v-list-item-content
                      v-if="vectorLabels.length > 0"
                      class="body-2"
                    >
                      <v-list-item-title
                        style="overflow-x: auto; text-overflow: clip;"
                      >
                        {{ label }}
                      </v-list-item-title>
                    </v-list-item-content>

                    <v-list-item-action v-if="vectorClickable[name.split('_serviceID:')[0]][index]">
                      <v-icon
                        v-if="active"
                        color="grey lighten-1"
                        small
                      >
                        mdi-eye-off
                      </v-icon>

                      <v-icon
                        v-else
                        small
                      >
                        mdi-eye
                      </v-icon>
                    </v-list-item-action>
                  </template>
                </v-list-item>
              </v-list-item-group>
            </v-list>
          </v-col>
        </v-row>
      </div>
    </v-card>
  </v-menu>
</template>

<script>
import SvgIcon from '@/components/utils/SvgIcon.vue';
import { mapActions, mapState } from 'vuex';

export default {
  components: {
    SvgIcon
  },
  props: {
  },
  data: () => ({
    env: '',
    rasterLayerName: '',
    legendMenu: false,
    overlay: false,
    paramName: {},
    vectorClickable: {},
    rasterClickable: [],
    vectorLayerName: [],
    envValues: [],
    rasterGradients: [],
    rasterLabels: [],
    vectorLabels: [],
    vectorColorsArray: [],
    vectorStrokesArray: [],
    vectorLabelsArray: [],
    vectorParamsArray: []
  }),
  computed: {
    ...mapState(['drawer', 'geoserver_data', 'map', 'app_user', 'file_transfer']),
    isLegendActive() {
      return this.map.OLmap.getLayers().getArray().slice(1).some(layer => layer.values_.layer_type === 'RASTER' || layer.values_.layer_type === 'VECTOR');
    },
    isRaster() {
      return this.map.OLmap.getLayers().getArray().slice(1).some(layer => layer.values_.layer_type === 'RASTER');
    },
    isVector() {
      return this.map.OLmap.getLayers().getArray().slice(1).some(layer => layer.values_.layer_type === 'VECTOR');
    }
    // dynamicGradient() {
    //   return `background: linear-gradient(to top, ${this.everyColors.slice(1).join(', ')}); height: 100%; width: 25px;border-radius: 5px;`;
    // }
  },
  watch: {
    $route() {}
  },
  methods: {
    ...mapActions(['getLegend', 'loadVectorLayer']),
    mobileBreakpoints() {
      switch (this.$vuetify.breakpoint.name) {
        case 'xs':
          return true;
        default:
          break;
      }
    },
    async layerLegend() {
      if (!this.legendMenu) {
        this.overlay = true;
        this.envValues = [];
        this.rasterGradients = [];
        this.rasterLabels = [];
        this.vectorLayerName = [];
        this.vectorLabelsArray = [];
        this.vectorColorsArray = [];
        this.vectorStrokesArray = [];
        this.vectorParamsArray = [];
        this.rasterClickable = [];
        let layerStyle = '';

        if (this.map.OLmap.getLayers().getArray().slice(1).some(layer => layer.values_.layer_type === 'RASTER')) {
          this.map.OLmap.getLayers().getArray().slice(1).filter(layer => {
            if (layer.values_.layer_type === 'RASTER') {
              this.rasterLayerName = `${layer.values_.layer_name}_serviceID:${layer.values_.service_id}`;
              layerStyle = layer.values_.layer_style;
            }
          });
          await this.getLegend({
            layer_name: this.rasterLayerName.split('_serviceID:')[0],
            layer_style: layerStyle
          });

          const colormapArray = this.geoserver_data.legendArray.data.Legend[0].rules[0].symbolizers[0].Raster.colormap.entries;
          colormapArray.forEach(obj => {
            if (obj.label !== undefined && !this.rasterLabels.includes(obj.label)) {
              this.rasterLabels.push(obj.label);
              this.envValues.unshift(`${obj.opacity.split('\'\'')[1]}:0`);

              if (obj.opacity !== '0.0') {
                this.rasterClickable.unshift(true);
              } else {
                this.rasterClickable.unshift(false);
              }
            }
          });

          this.rasterLabels.forEach(label => {
            const tempArray = colormapArray.filter(item => item.label === label);
            const colors = tempArray.map(item => item.color);
            this.rasterGradients.push(colors);
          });
        }


        if (this.map.OLmap.getLayers().getArray().slice(1).some(layer => layer.values_.layer_type === 'VECTOR')) {
          for await (const layer of this.map.OLmap.getLayers().getArray().slice(1)) {
            if (layer.values_.layer_type === 'VECTOR') {
              await this.getLegend({
                layer_name: layer.values_.layer_name,
                layer_style: layer.values_.layer_style
              });
              const vectorColors = [];
              const vectorStrokes = [];
              this.vectorLabels = [];
              const vectorParams = [];
              const fillOpacity = [];
              this.vectorLayerName.push(`${layer.values_.layer_name}_serviceID:${layer.values_.service_id}`);
              const colormapArray = this.geoserver_data.legendArray.data.Legend[0].rules;
              colormapArray.forEach(obj => {
                if (obj.symbolizers[0].Point) {
                  if (obj.symbolizers[0].Point.graphics[0]['fill-opacity'] == 1) {
                    fillOpacity.push(!obj.name.includes('geom_property'));
                  } else {
                    fillOpacity.push(false);
                  }

                  if (obj.symbolizers[0].Point.graphics[0].fill) {
                    const color = Array.isArray(obj.symbolizers[0].Point.graphics[0].fill) ? obj.symbolizers[0].Point.graphics[0].fill : [obj.symbolizers[0].Point.graphics[0].fill];
                    vectorColors.unshift(...color);
                  } else {
                    vectorColors.push('red');
                  }

                  const colorStroke = Array.isArray(obj.symbolizers[0].Point.graphics[0].stroke) ? obj.symbolizers[0].Point.graphics[0].stroke : [obj.symbolizers[0].Point.graphics[0].stroke];
                  const stroke = obj.symbolizers[0].Point.graphics[0].stroke ? colorStroke : ['black'];
                  vectorStrokes.unshift(...stroke);

                  this.vectorLabels.push(obj.title);

                  if (obj.filter && obj.filter.includes('type_property')) {
                    vectorParams.unshift(`${obj.filter.split('\'')[1]},=`);
                    this.paramName[layer.values_.layer_name] = 'type_property';
                  } else {
                    vectorParams.unshift(obj.name);
                    this.paramName[layer.values_.layer_name] = 'standard_property';
                  }
                }

                if (obj.symbolizers[0].Polygon) {
                  this.paramName[layer.values_.layer_name] = 'standard_property';
                  const color = Array.isArray(obj.symbolizers[0].Polygon.fill) ? obj.symbolizers[0].Polygon.fill : [obj.symbolizers[0].Polygon.fill];
                  const colorStroke = Array.isArray(obj.symbolizers[0].Polygon.stroke) ? obj.symbolizers[0].Polygon.stroke : [obj.symbolizers[0].Polygon.stroke];
                  const stroke = obj.symbolizers[0].Polygon.stroke ? colorStroke : ['black'];
                  vectorColors.unshift(...color);
                  vectorStrokes.unshift(...stroke);

                  if (obj.filter && !obj.name.includes('geom_property')) {
                    if (obj.filter.split('AND').length > 1) {
                      vectorParams.unshift(obj.name);
                    } else {
                      vectorParams.unshift(`${obj.filter.split('\'')[1]},=`);
                    }
                  }

                  this.vectorLabels.push(obj.title);
                  fillOpacity.push(obj.filter && !obj.name.includes('geom_property'));
                }

                if (obj.symbolizers[0].Line) {
                  const color = Array.isArray(obj.symbolizers[0].Line.stroke) ? obj.symbolizers[0].Line.stroke : [obj.symbolizers[0].Line.stroke];
                  vectorColors.unshift(...color);
                  vectorStrokes.unshift('black');
                  this.vectorLabels.push(obj.title);
                  fillOpacity.push(!obj.name.includes('geom_property'));
                }
              });
              this.vectorColorsArray.push(vectorColors);
              this.vectorStrokesArray.push(vectorStrokes);
              this.vectorLabelsArray.push(this.vectorLabels);
              this.vectorParamsArray.push(vectorParams);

              if (!(layer.values_.layer_name in this.geoserver_data.vectorLegend_selectedLabel)) {
                this.geoserver_data.vectorLegend_selectedLabel[layer.values_.layer_name] = [];
              }

              this.vectorClickable[layer.values_.layer_name] = fillOpacity;
            }
          }
        }

        this.overlay = false;
      }
    },
    setEnv(index) {
      if (this.rasterClickable[index]) {
        const filteredEnv = this.envValues.filter(env => this.geoserver_data.rasterLegend_selectedLabel.includes(this.envValues.indexOf(env)));
        this.env = filteredEnv.join(';');

        this.map.OLmap.getLayers().getArray().forEach(layer => {
          if (layer.values_.layer_type === 'RASTER' && layer.values_.layer_name === this.rasterLayerName.split('_serviceID:')[0]) {
            layer.getSource().updateParams({ ENV: this.env });
          }
        });
        this.env = '';
      }
    },
    async setParamRange(layerName, i, bool) {
      if (bool) {
        const param_range = {};
        param_range[layerName] = [[], []];

        this.geoserver_data.vectorLegend_selectedLabel[layerName].forEach(item => {
          if (this.vectorParamsArray[i][item].split(',').length > 2) {
            param_range[layerName][0].push(this.vectorParamsArray[i][item].replace(',<=,', ',').replace(',<,', ','));
          } else {
            param_range[layerName][1].push(this.vectorParamsArray[i][item].replace(',=', ''));
          }
        });

        for await (const layer of this.map.OLmap.getLayers().getArray().slice(1)) {
          if (layer.values_.layer_type === 'VECTOR' && layer.values_.layer_name === layerName) {
            const paramRangeCondition = param_range[layerName][0].length !== 0 || param_range[layerName][1].length !== 0;
            this.map.vectorParamRange[layer.values_.service_id] = paramRangeCondition
              ? `[[${param_range[layerName][0].sort()}], [${param_range[layerName][1]}], "${this.paramName[layerName]}"]`
              : undefined;

            const vectorParams = {
              params: {
                GROUP: this.$route.params.id,
                LAYERS: layer.values_.layer_name,
                TIME: layer.values_.time,
                STYLES: layer.values_.layer_style,
                USERNAME: this.app_user.username,
                SERVICE_NAME: layer.values_.service_name,
                PARAM_RANGE: this.map.vectorParamRange[layer.values_.service_id]
              },
              TYPE: 'VECTOR',
              LAYER_SERVICE_ID: layer.values_.service_id,
              EXTENT: this.geoserver_data.geographicDomains[layer.values_.service_id]
            };

            layer.set('layer_type', 'REMOVE');
            await this.loadVectorLayer(vectorParams);
          }
        }
      }
    }
  }
};
</script>

<style scoped>
.top-component-class {
  position: fixed;
  width: 100%;
  bottom: 0;
  z-index: 2;
  margin-left: 5em;
  /* box-shadow: inset 0 -50vh 8vh -45vh rgba(0,0,0,0.8) !important; */
  /* top: 87vh; */
  /* left: 6.5em; */
}
@media (max-device-width:599px){
  .button-legend {
    margin: 4px!important;
  }

  .menu {
    max-width: calc(100% - 32px);
    width:min-content;
    max-height: 200px;
  }
}
.white-bg {
  background-color: rgb(255, 255, 255, .85);
  min-height: 100%;
}
.transparent-bg {
  background-color: transparent;
  scrollbar-width: thin;
}
.flex-no-wrap {
  flex-wrap: nowrap;
}
</style>
